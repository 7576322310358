<template>
    <div class="container">
        <van-cell-group title="菜单">
            <van-cell
                title="获取二维码"
                is-link
                to="wxapp/index?communityId=A12CCC9339CF2E8D1A016C1B0846E97D&dong=2110&ho=1103&ownerPhone=13651000008&ownerName=哈哈京"
            />
<!--            <van-cell title="访客" is-link to="wxapp/visitor" />
            <van-cell title="通行证" is-link to="wxapp/pass" />-->
            <van-cell title="专家填报邀请" is-link to="exp/invite?inviteId=1" />
        </van-cell-group>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { Button, Cell, CellGroup } from 'vant';

    export default {
        components: {
            [Button.name]: Button,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
        },
        data() {
            return {
                authCode: null,
            };
        },
        computed: {},
        methods: {
            ...mapActions(['operate']),
        },
        created() {},
        mounted() {
            this.authCode = this.$route.name;
        },
    };
</script>
<style lang="less" scoped>
    @import 'styles/core.less';
</style>
